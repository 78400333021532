import React from 'react';
import { CreditCardInput } from 'components/purchase/CreditCardInput';
import {
  ValidatedForm,
  ValidatedInput,
} from 'components/common/validated-form';
import {
  StyledFormLabel,
  StyledValidatedInput,
  StyledValidatedButton,
  StyledValidatedSelect,
} from 'components/common/StyledForm';
import { FormCols } from 'components/common/json_form';
import { ExpirationInput } from 'components/purchase/ExpirationInput';
import usKeyFilter from 'util/usKeyfilter';
import { InCurrency } from 'components/common/InCurrency';
import { useFuseBillPurchase } from '../useFuseBillPurchase';
import { CEB_PORTAL_URL, RECAPTCHA_SITEKEY } from 'app-constants';
import Recaptcha from 'react-recaptcha';
import { UpdatedStyledFormOverrides } from 'components/layouts/twentytwenty/UpdatedStyledFormOverrides';
import {
  StyledFormErrorsList,
  StyledFormErrorsListItem,
} from 'components/login/FormErrorsList';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export default function PurchaseCheckoutForm({ user, price, ...props }) {
  const gutter = 25;

  const [recaptcha, setRecaptcha] = React.useState('');
  const [errors, setErrors] = React.useState();

  const onErrors = React.useCallback(setErrors);

  const onSuccess = React.useCallback(() => {
    window.parent.postMessage({ cmd: 'subscribe' }, CEB_PORTAL_URL);
  });

  const [processing, onPurchase] = useFuseBillPurchase(onSuccess, onErrors);

  const verifyCallback = React.useCallback(response => {
    setRecaptcha(response);
  });

  return (
    <StyledCheckoutForm>
      <UpdatedStyledFormOverrides>
        <ValidatedForm onSubmit={onPurchase}>
          <input
            id="customerId"
            name="customerId"
            type="hidden"
            value={user?.customerId}
          />

          <FormCols gutter={gutter}>
            <StyledFormLabel label="First Name">
              <StyledValidatedInput
                type="text"
                name="firstName"
                keyPressFilter={usKeyFilter}
                defaultValue={props.forFirm ? null : user?.firstName}
                required
              />
            </StyledFormLabel>
            <StyledFormLabel label="Last Name">
              <StyledValidatedInput
                type="text"
                name="lastName"
                keyPressFilter={usKeyFilter}
                defaultValue={props.forFirm ? null : user?.lastName}
                required
              />
            </StyledFormLabel>
          </FormCols>
          <h2>
            <i className="fas fa-lock" />
            Credit Card
          </h2>

          <div className="credit">
            <p>
              Your credit info is safe with us–we protect it according to the
              highest industry standards.
            </p>

            <CreditCardInput />

            <FormCols gutter={gutter}>
              <ExpirationInput />
              <StyledFormLabel
                label={
                  <span className="security-code-label">
                    Security Code <i className="fas fa-question-circle" />
                  </span>
                }
              >
                <StyledValidatedInput
                  name="cvv"
                  type="text"
                  maxLength={5}
                  validator={/^\d{3,5}$/}
                  required
                />
              </StyledFormLabel>
            </FormCols>
          </div>
          <StyledFormLabel
            label="Address Line 1"
            hint="Street address, P.O box, company name"
          >
            <StyledValidatedInput
              type="text"
              name="address1"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.line1}
              required
            />
          </StyledFormLabel>

          <StyledFormLabel
            label="Address Line 2"
            hint="Apartment, suite, unit, building, floor, etc."
          >
            <StyledValidatedInput
              type="text"
              name="address2"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.line2}
            />
          </StyledFormLabel>

          <StyledFormLabel label="City">
            <StyledValidatedInput
              type="text"
              name="city"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.city}
              required
            />
          </StyledFormLabel>

          <FormCols gutter={gutter} className="billing-state-zip">
            <StyledFormLabel label="State">
              <StyledValidatedSelect
                name="stateId"
                defaultValue={
                  props.user?.addressInfo?.billingAddress?.stateId || '18'
                }
                required
              >
                <option value="15">Alabama</option>
                <option value="14">Alaska</option>
                <option value="16">Arizona</option>
                <option value="17">Arkansas</option>
                <option value="18">California</option>
                <option value="19">Colorado</option>
                <option value="20">Connecticut</option>
                <option value="21">Delaware</option>
                <option value="22">District of Columbia</option>
                <option value="23">Florida</option>
                <option value="24">Georgia</option>
                <option value="25">Hawaii</option>
                <option value="26">Idaho</option>
                <option value="27">Illinois</option>
                <option value="28">Indiana</option>
                <option value="29">Iowa</option>
                <option value="30">Kansas</option>
                <option value="31">Kentucky</option>
                <option value="32">Louisiana</option>
                <option value="33">Maine</option>
                <option value="34">Maryland</option>
                <option value="35">Massachusetts</option>
                <option value="36">Michigan</option>
                <option value="37">Minnesota</option>
                <option value="38">Mississippi</option>
                <option value="39">Missouri</option>
                <option value="40">Montana</option>
                <option value="41">Nebraska</option>
                <option value="42">Nevada</option>
                <option value="43">New Hampshire</option>
                <option value="44">New Jersey</option>
                <option value="45">New Mexico</option>
                <option value="46">New York</option>
                <option value="47">North Carolina</option>
                <option value="48">North Dakota</option>
                <option value="49">Ohio</option>
                <option value="50">Oklahoma</option>
                <option value="51">Oregon</option>
                <option value="52">Pennsylvania</option>
                <option value="53">Puerto Rico</option>
                <option value="54">Rhode Island</option>
                <option value="55">South Carolina</option>
                <option value="56">South Dakota</option>
                <option value="57">Tennessee</option>
                <option value="58">Texas</option>
                <option value="59">Utah</option>
                <option value="60">Vermont</option>
                <option value="61">Virginia</option>
                <option value="62">Washington</option>
                <option value="63">West Virginia</option>
                <option value="64">Wisconsin</option>
                <option value="65">Wyoming</option>
              </StyledValidatedSelect>
            </StyledFormLabel>
            <StyledFormLabel label="Zip Code">
              <StyledValidatedInput
                label="Zip Code"
                type="text"
                name="zipCode"
                defaultValue={
                  props.user?.addressInfo?.billingAddress?.postalZip
                }
                validator={/^\d{5}$/}
                required
              />
            </StyledFormLabel>
          </FormCols>

          <input id="countryId" name="countryId" type="hidden" value="840" />
          <input
            id="makeDefault"
            name="makeDefault"
            type="hidden"
            value="true"
          />
          <input
            id="collectionAmount"
            name="collectionAmount"
            type="hidden"
            value={price}
          />
          <ValidatedInput
            name="recaptcha"
            type="hidden"
            value={recaptcha}
            required
          />
          <Recaptcha
            sitekey={RECAPTCHA_SITEKEY}
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={window.onloadRecaptcha}
          />
          {errors && (
            <StyledFormErrorsList>
              {errors.map((error, i) => {
                let Key, message;
                if (typeof error === 'string') {
                  Key = `error-${i}`;
                  message = error;
                } else {
                  let { Key, Value: message } = error;
                }

                return (
                  <StyledFormErrorsListItem key={`error-${Key}`}>
                    {message}
                  </StyledFormErrorsListItem>
                );
              })}
            </StyledFormErrorsList>
          )}
          <StyledValidatedButton
            className="registration-button"
            disabled={processing}
          >
            Charge my card <InCurrency price={price} />
          </StyledValidatedButton>
        </ValidatedForm>
      </UpdatedStyledFormOverrides>
    </StyledCheckoutForm>
  );
}

const StyledCheckoutForm = styled.section`
  box-sizing: border-box;
  height: 100%;
  padding: 75px 120px 45px;
  #g-recaptcha {
    padding-bottom: 24px;
  }
  h2 {
    margin-bottom: 24px;
    i {
      margin-right: 0.5em;
    }
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    margin-bottom: 100px;
    margin-right: 24px;
    min-width: 600px;
  }
  .credit {
    border: 1px solid ${CEB_COLOR('BOTTICELLI')};
    margin-bottom: 34px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 20px;
    background: #ecf7ff;
    border-radius: 3px;
    p {
      margin-bottom: 1.5em;
    }
  }
  .security-code-label {
    position: relative;
    display: block;
  }
  .security-code-label .fa-question-circle {
    opacity: 0.25;
    margin-left: 0.5em;
    position: absolute;
    right: 0.6em;
    bottom: -2.5em;
  }
`;
