import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const SpinnerButtonMessage = ({ message = 'Processing', children }) => (
  <StyledSpinnerButtonMessage>
    <i className="fa fa-spinner fa-spin" />
    {children || message}
  </StyledSpinnerButtonMessage>
);

const StyledSpinnerButtonMessage = styled.div`
  color: ${CEB_COLOR('COOL_BLUE')};
  font-size: 16px;
  margin-top: 20px;

  i {
    margin-right: 0.5em;
  }
`;
