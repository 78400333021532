import React from 'react';
import { FormCols } from 'components/common/json_form';
import { range, padCharsStart } from 'lodash/fp';
import { StyledFormLabel, StyledValidatedSelect } from '../common/StyledForm';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export const ExpirationInput = props => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  return (
    <StyledExpirationInput>
      <StyledFormLabel label="Card Expiration">
        <FormCols gutter={5}>
          <StyledValidatedSelect
            name="expirationMonth"
            type="text"
            validator={/^\d{1,2}$/}
            defaultValue={currentMonth + 1}
            required
          >
            {range(0, 12).map(v => (
              <option value={v + 1} key={v}>
                {padCharsStart(0)(2)(v + 1)}
              </option>
            ))}
          </StyledValidatedSelect>
          <span>/</span>
          <StyledValidatedSelect
            name="expirationYear"
            type="text"
            validator={/^\d{2}$/}
            defaultValue={`${currentYear}`.slice(-2)}
            required
          >
            {range(currentYear, currentYear + 11).map(v => (
              <option value={`${v}`.slice(-2)} key={v}>
                {`${v}`.slice(-2)}
              </option>
            ))}
          </StyledValidatedSelect>
        </FormCols>
      </StyledFormLabel>
    </StyledExpirationInput>
  );
};

const StyledExpirationInput = styled.div`
  div {
    max-width: 1em;
    text-align: center;
    &:first-child,
    &:last-child {
      max-width: inherit;
    }
    select {
      padding-left: 7px;
      padding-right: 7px;
      box-shadow: none;
    }

    span {
      text-align: center;
      font-weight: bold;
      margin-top: 12px;
      display: inline-block;
    }
  }

  .form-cols .form-col-1 {
    flex: 0 1 auto;
    margin: 0 9px 0 4px;
  }
`;
