import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const UpdatedStyledFormOverrides = styled.div`
  .password-hint {
    font-family: 'BasisGrotesque', sans-serif;
    label {
      display: block;
      font-size: 18px;
      line-height: 1.44;
      margin-bottom: 18px;
    }
    ul {
      margin-bottom: 36px;
    }
  }

  form {
    font-family: 'BasisGrotesque', sans-serif;
    > div {
      &,
      &.registration-button {
        margin-bottom: 32px;
        button {
          border-radius: 5px;
          box-sizing: border-box;
          font-family: 'BasisGrotesque', sans-serif;
          font-size: 18px;
          line-height: 1.4;
          max-width: 100%;
          padding: 13px 24px 10px;
          width: 100%;
        }
      }
      &.registration-button {
        margin-top: 36px;
      }
      &[data-type='password'] {
        margin-bottom: 13px;
      }
    }
    .password-strength {
      margin-bottom: 36px !important;
    }
    .bar-number h4 {
    }
    label {
      &.bar-number .label-required {
        color: ${CEB_COLOR('BRICK_RED')};
      }
      .label-text {
        font-size: 16px !important;
      }
      .label-required {
        font-size: 12px !important;
      }
    }
    input[type='text'],
    input[type='password'] {
      background: white;
      border-radius: 3px;
    }
    select {
      background: white;
    }

    .error {
      color: ${CEB_COLOR('TUSCANY')};
    }

    .g-recaptch {
      margin-bottom: 24px;
    }
    input,
    select {
      background: white;
    }

    button {
      background: ${CEB_COLOR('BLUE_RIBBON')};
      border-radius: 5px;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 18px;
      line-height: 1.4;
      padding: 13px 24px 10px;
      width: 100%;
      max-width: 360px;
      span span {
        font-family: inherit !important;
        font-weight: bold !important;
      }
    }

    .form-cols {
      padding-top: 1em;
      width: 100%;
    }
    .credit .form-cols {
      padding-top: 0;
    }
    .credit > .form-cols > .form-col-0 {
      flex: 0 1 auto;
      max-width: none;
      select {
        background: white;
      }
    }
    .credit > .form-cols > .form-col-1 {
      max-width: none;
      min-width: 150px;
      flex: 1 1 auto;
    }
  }
`;
