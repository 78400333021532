import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/fp/debounce';
// see: https://tobbelindstrom.com/blog/resize-observer-hook/

interface ResizeObserverEntry {
  target?: Element;
  contentRect?: DOMRectReadOnly;
}

const useResizeObserver = (): [
  (node: Element | null) => void,
  ResizeObserverEntry,
  Element | null,
] => {
  const [entry, setEntry] = React.useState<ResizeObserverEntry>({});
  const [node, setNode] = React.useState<Element | null>(null);
  const observer = React.useRef<ResizeObserver | null>(null);

  const disconnect = React.useCallback(() => {
    const { current } = observer;
    current && current.disconnect();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeCallback = React.useCallback(
    debounce(100, ([entry]) => {
      setEntry(entry);
    }),
    [],
  );

  const observe = React.useCallback(() => {
    observer.current = new ResizeObserver(resizeCallback);
    node && observer.current.observe(node);
  }, [node, resizeCallback]);

  React.useLayoutEffect(() => {
    observe();
    return disconnect;
  }, [disconnect, observe]);

  return [setNode, entry, node];
};

export default useResizeObserver;
