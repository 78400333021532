import React from 'react';
import { FormCols } from 'components/common/json_form';
import { CreditCardInput } from './CreditCardInput';
import { ValidatedForm } from 'components/common/validated-form';

import {
  StyledFormLabel,
  StyledValidatedInput,
  StyledValidatedButton,
} from '../common/StyledForm';
import PurchasePage from './PurchasePage';
import { useNavigate } from 'react-router';
import { ExpirationInput } from './ExpirationInput';
import usKeyFilter from 'util/usKeyfilter';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

const gutter = 25;

export function BillingInformation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const onSubmit = React.useCallback(
    props.onSubmit ||
      (billing => {
        navigate('/address', { state: billing });
      }),
  );

  const errors =
    props.errors ||
    (location &&
      location.state &&
      location.state.errors &&
      location.state.errors) ||
    [];

  return (
    <PurchasePage title="Your Billing Information">
      <StyledBillingInformation>
        {errors.map(({ Key, Value }) => (
          <p className="error" key={Key}>
            {Value}
          </p>
        ))}
        <p>Please enter your billing information:</p>
        <ValidatedForm onSubmit={onSubmit}>
          <input
            id="customerId"
            name="customerId"
            type="hidden"
            value={props.user.customerId}
          />

          <FormCols gutter={gutter}>
            <StyledFormLabel label="First Name">
              <StyledValidatedInput
                type="text"
                name="firstName"
                keyPressFilter={usKeyFilter}
                defaultValue={props.forFirm ? null : props.user.firstName}
                required
              />
            </StyledFormLabel>
            <StyledFormLabel label="Last Name">
              <StyledValidatedInput
                type="text"
                name="lastName"
                keyPressFilter={usKeyFilter}
                defaultValue={props.forFirm ? null : props.user.lastName}
                required
              />
            </StyledFormLabel>
          </FormCols>
          <h2>
            <i className="fas fa-lock" />
            Credit Card
          </h2>

          <div className="credit">
            <p>
              Your credit info is safe with us&mdash; we protect it according to
              the highest industry standards.
            </p>

            <FormCols gutter={gutter}>
              <CreditCardInput />
              <ExpirationInput />
              <StyledFormLabel
                label={
                  <span className="security-code-label">
                    Security Code <i className="fas fa-question-circle" />
                  </span>
                }
              >
                <StyledValidatedInput
                  name="cvv"
                  type="text"
                  maxLength={5}
                  validator={/^\d{3,5}$/}
                  required
                />
              </StyledFormLabel>
            </FormCols>
          </div>

          <StyledValidatedButton type="submit">
            Next: Address
          </StyledValidatedButton>
        </ValidatedForm>
      </StyledBillingInformation>
    </PurchasePage>
  );
}

const StyledBillingInformation = styled.div`
  button {
    min-width: 188px;
    white-space: nowrap;
  }

  p {
    line-height: 1.8;
    margin-bottom: 15px;
  }

  .error {
    color: ${CEB_COLOR('TUSCANY')};
  }

  h2 i {
    margin-right: 0.5em;
  }

  h2 {
    font-size: 18px;
    margin: 8px 0 12px 0;
  }

  .credit {
    border: 2px solid ${CEB_COLOR('ALTO')};
    margin-bottom: 34px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 20px;
  }
  .security-code-label {
    position: relative;
    display: block;
  }
  .security-code-label .fa-question-circle {
    opacity: 0.25;
    margin-left: 0.5em;
    position: absolute;
    right: 0.6em;
    bottom: -2.5em;
  }

  .form-cols {
    padding-top: 1em;
    width: 100%;
  }
  .credit > .form-cols .form-col-1 {
    max-width: 170px;
    flex: 0 1 auto;
  }
  .credit > .form-cols .form-col-2 {
    max-width: 110px;
  }
  label .form-cols {
    padding-top: 0;
  }
`;
