import { PURCHASE_API_KEY } from 'app-constants';
export function postFusebill(form) {
  const successUri = undefined;
  const failUri = undefined;
  const publicApiKey = PURCHASE_API_KEY;
  const data = {
    CustomerId: form.customerId,
    PublicApiKey: publicApiKey,
    SuccessUri: successUri,
    FailUri: failUri,
    CardNumber: form.cardNumber,
    FirstName: form.firstName,
    LastName: form.lastName,
    ExpirationMonth: form.expirationMonth,
    ExpirationYear: form.expirationYear,
    makeDefault: form.makeDefault,
    Cvv: form.cvv,
    address1: form.address1,
    address2: form.address2 || '',
    city: form.city,
    stateId: form.stateId,
    countryId: form.countryId,
    postalZip: form.zipCode,
    recaptcha: form.recaptcha,
  };

  if (form.collectionAmount) {
    data.paymentCollectOptions = {
      collectionAmount: parseFloat(form.collectionAmount),
    };
  }

  return fetch('https://payments.subscriptionplatform.com/api/paymentsv2/', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });
}
