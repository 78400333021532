import React from 'react';
import { ValidatedFormContext } from '.';

function validate(
  value: string | number | readonly string[] | undefined,
  validator?:
    | ((
        value: string,
        event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => boolean)
    | RegExp,
  event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
): boolean {
  return validator
    ? typeof validator === 'function'
      ? validator(value as string, event)
      : (validator as RegExp).test(value as string)
    : value
      ? true
      : false;
}

export interface ValidatedProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  element: React.ElementType;
  validator?:
    | ((
        value: string,
        event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => boolean)
    | RegExp;
  validateOn?: string;
  keyPressFilter?: (charCode: number) => boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  mask?: string;
  placeholderChar?: string;
}

export const Validated = React.forwardRef<
  HTMLInputElement | HTMLSelectElement,
  ValidatedProps
>(
  (
    {
      element,
      onChange,
      validator,
      required,
      name,
      validateOn,
      keyPressFilter,
      onKeyPress,
      onKeyDown,
      onClick,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const context = React.useContext(ValidatedFormContext);

    const [value, setValue] = React.useState<
      string | number | readonly string[] | undefined
    >(props.value || props.defaultValue);

    const [valid, setValid] = React.useState<boolean>(
      required ? validate(props.value || props.defaultValue, validator) : true,
    );

    const validateIfRequired = React.useMemo(() => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        const {
          target: { value },
        } = event;

        setValid(
          !required && !value ? true : validate(value, validator, event),
        );
      };
    }, [required, setValid, validator]);

    const change = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {
          target: { value },
        } = event;

        validateIfRequired(event);
        setValue(value);
        onChange && onChange(event);
      },
      [onChange, setValue, validateIfRequired],
    );

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLInputElement>) => {
        if (context && context.setHideValidity) {
          context.setHideValidity(false);
        }
        validateIfRequired(
          event as unknown as React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement
          >,
        );
        onClick && onClick(event);
      },
      [onClick, validateIfRequired, context],
    );

    const handleBlur = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        validateIfRequired(e as unknown as React.ChangeEvent<HTMLInputElement>);
        onBlur && onBlur(e);
      },
      [validateIfRequired, onBlur],
    );

    React.useEffect(() => {
      if (context && name) {
        context.setValidity(name, valid);
      }
    }, [valid, context, name]);

    const keyPress = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (keyPressFilter && !keyPressFilter(event.charCode)) {
          event.preventDefault();
          return false;
        }
        if (onKeyPress) return onKeyPress(event);
        return true;
      },
      [keyPressFilter, onKeyPress],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) return onKeyDown(event);
        return true;
      },
      [onKeyDown],
    );

    React.useEffect(() => {
      if (props.value) {
        setValue(props.value);
        const event = {
          target: {
            value: props.value,
          },
        } as React.ChangeEvent<HTMLInputElement>;
        validateIfRequired(event);
      }
    }, [props.value, validateIfRequired]);

    React.useEffect(() => {
      //remove field from validated state
      return () => {
        context?.removeField(name ?? '');
      };
    }, [context, name]);

    return React.createElement(
      element,
      {
        ...props,
        ...(valid || context?.hideValidity ? {} : { 'data-invalid': true }),
        name,
        required,
        ...(element === 'select' && !value
          ? { 'data-show-placeholder': true }
          : {}),
        onClick: handleClick,
        onChange: change,
        onInput: change,
        onBlur: handleBlur,
        onKeyPress: keyPress,
        onKeyDown: handleKeyDown,
        ref,
      },
      props.children,
    );
  },
);

Validated.displayName = 'Validated';
