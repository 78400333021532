import React from 'react';
import creditCardType from 'credit-card-type';

import max from 'lodash/fp/max';
import { StyledFormLabel, StyledValidatedInput } from '../common/StyledForm';
import styled from 'styled-components';

const validCards = ['visa', 'mastercard', 'american-express'];

export const CreditCardInput = props => {
  const [number, setNumber] = React.useState('');

  const onChange = React.useCallback(({ target: { value } }) => {
    setNumber(value);
  });

  const cardTypes = creditCardType(number).filter(card =>
    validCards.includes(card.type),
  );

  const cards = cardTypes.map(card => card.type);

  const maxLength =
    cardTypes.length === 1 ? max(cardTypes[0].lengths) : undefined;

  const validateCard = React.useCallback(value => {
    const cards = creditCardType(value);

    if (cards.length === 1) {
      const card = cards[0];

      return card.lengths.includes(value.length);
    }
  });

  return (
    <StyledCreditCardInput>
      <StyledFormLabel label="Card Number">
        <StyledValidatedInput
          name="cardNumber"
          type="text"
          onChange={onChange}
          validator={validateCard}
          maxLength={maxLength}
          required
        />
      </StyledFormLabel>
      <div>
        <div className="icons">
          {cards.includes('visa') ? <i className="fab fa-cc-visa" /> : null}
          {cards.includes('mastercard') ? (
            <i className="fab fa-cc-mastercard" />
          ) : null}
          {cards.includes('american-express') ? (
            <i className="fab fa-cc-amex" />
          ) : null}
        </div>
      </div>
    </StyledCreditCardInput>
  );
};

const StyledCreditCardInput = styled.div`
  display: flex;
  .icons {
    margin-top: 34px;
    padding-bottom: 33px;
    display: flex;
    justify-content: space-between;
  }

  i {
    font-size: 26px;
    margin: 0 0 0 0.5em;
  }
  .fa-cc-visa {
    color: #0157a2;
  }
  .fa-cc-mastercard {
    color: #0a3a82;
  }
  .fa-cc-amex {
    color: #007bc1;
  }
  .fa-cc-discover {
    color: #f68121;
  }
  > label {
    flex: 1 1 auto;
  }
  > div {
    flex: 0 1 auto;
  }
`;
