import React from 'react';
import { BillingInformation } from './BillingInformation';
import { BillingAddress } from './BillingAddress';
import { CEB_PORTAL_URL } from 'app-constants';
import StatusMessage from 'components/common/StatusMessage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PurchaseCheckoutForm from './checkout-form';
import useResizeObserver from 'hooks/useResizeObserver';
import { createGlobalStyle } from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { StyledGlobalApp } from 'components/app/StyledGlobalApp';

export default function Purchase() {
  const [price, setPrice] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [formType, setFormType] = React.useState('sidebar');
  const [height, setHeight] = React.useState(800);

  const receiveMessage = React.useCallback(event => {
    if (event.origin !== CEB_PORTAL_URL) {
      return;
    }

    const { data } = event;

    switch (data.cmd) {
      case 'setPrice':
        setPrice(data.price);
        break;
      case 'setUser':
        setUser(data.user);
        break;
      case 'setFormType':
        setFormType(data.formType);
        break;
      case 'setHeight':
        setHeight(event.data.height);
        break;

      default:
        console.error(`unknown cmd: ${data.cmd}`);
    }
  });

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    window.parent.postMessage({ cmd: 'getPrice' }, CEB_PORTAL_URL);
    window.parent.postMessage({ cmd: 'getFormType' }, CEB_PORTAL_URL);
    window.parent.postMessage({ cmd: 'getUser' }, CEB_PORTAL_URL);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const [purchaseRef, { contentRect }, purchaseNode] = useResizeObserver();

  React.useLayoutEffect(() => {
    window.parent &&
      window.parent.postMessage(
        {
          cmd: 'setHeight',
          height: purchaseNode?.clientHeight,
        },
        CEB_PORTAL_URL,
      );
  }, [contentRect]);

  if (!user) {
    return <StatusMessage icon="fa-spinner fa-spin">Loading...</StatusMessage>;
  }
  return (
    <div ref={purchaseRef}>
      {formType === 'sidebar' && (
        <Router>
          <Routes>
            <Route
              path="/address"
              element={<BillingAddress user={user} price={price} />}
            />
            <Route path="/" element={<BillingInformation user={user} />} />
          </Routes>
        </Router>
      )}
      {formType === 'checkout' && (
        <PurchaseCheckoutForm user={user} price={price} />
      )}
      <StyledPurchaseGlobals />
      <StyledGlobalApp />
    </div>
  );
}

const StyledPurchaseGlobals = createGlobalStyle`
  * {
    background: transparent;
    border: 0;
    color: inherit;
    margin: 0;
    padding: 0;
    &:focus {
      outline-color: ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      outline-style: solid;
      outline-width: 1px;
    }
  }

  body {
    background-color: transparent;
    font-family: 'Zilla Slab', serif;
    color: ${CEB_COLOR('BLACK')};
    margin: 0;
    padding: 0;
  }
`;
