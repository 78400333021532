import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export default function PurchasePage(props) {
  return (
    <StyledPurchasePage>
      <h1>{props.title}</h1>
      <hr />
      {props.children}
    </StyledPurchasePage>
  );
}

export const StyledPurchasePage = styled.div`
  color: ${CEB_COLOR('BLACK')};
  margin: 0 auto;
  padding: 35px 40px;

  > hr {
    border-bottom: 1px solid ${CEB_COLOR('ALTO')};
    margin: 22px 0 20px 0;
  }

  > h1 {
    font-size: 22px;
  }
`;
