import React from 'react';
import { Validated, ValidatedProps } from './Validated';

export interface ValidatedSelectProps extends Omit<ValidatedProps, 'element'> {
  placeholder?: string;
  children?: React.ReactNode;
}

export const ValidatedSelect = React.forwardRef<
  HTMLSelectElement,
  ValidatedSelectProps
>(({ placeholder, ...props }, ref) => {
  return (
    <Validated element="select" {...props} ref={ref}>
      {placeholder && (
        <option hidden={props.required} value="">
          {placeholder}
        </option>
      )}
      {props.children}
    </Validated>
  );
});

ValidatedSelect.displayName = 'ValidatedSelect';
