import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface StyleBlueButtonProps {
  fullWidth?: boolean;
  dark?: boolean;
  outline?: boolean;
  fontWeight?: string;
  fontSize?: string;
  bgColor?: string;
  padding?: string;
  backgroundColor?: string;
}

function styleBlueButton(component: React.ComponentType<any>) {
  return styled(component)
    .withConfig({
      shouldForwardProp: (prop: string | number | symbol) =>
        ['to', 'children', 'disabled', 'type', 'onClick', 'state'].includes(
          prop as string,
        ),
    })
    .attrs<StyleBlueButtonProps>(
      ({
        fullWidth,
        dark,
        outline,
        fontWeight = 'bold',
        fontSize = '18px',
        bgColor = 'COLOR_BLUE_RIBBON',
        padding,
      }) => {
        const backgroundColor = `var(--${dark ? 'COLOR_CEB_HEADER_BLUE' : bgColor})`;
        return {
          fullWidth,
          dark,
          outline,
          fontWeight,
          fontSize,
          bgColor,
          padding,
          backgroundColor,
        };
      },
    )`
    display: inline-block;
    box-sizing: border-box;

    background: ${({ outline, backgroundColor }: StyleBlueButtonProps) =>
      outline ? 'transparent' : backgroundColor};
    border: ${({ outline }: StyleBlueButtonProps) =>
      outline ? `solid 2px ${CEB_COLOR('BLUE_RIBBON')}` : 'none'};
    border-radius: 5px;
    color: ${({ outline }: StyleBlueButtonProps) => (outline ? CEB_COLOR('BLUE_RIBBON') : 'white')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: ${({ fontSize }: StyleBlueButtonProps) => fontSize};
    font-weight: ${({ fontWeight }: StyleBlueButtonProps) => fontWeight};
    line-height: 1.5;
    padding: ${({ padding }: StyleBlueButtonProps) => (padding ? padding : '13px 20px 10px')};
    text-align: center;
    text-decoration: none;
    ${({ fullWidth }: StyleBlueButtonProps) => (fullWidth ? 'width: 100%;max-width:none;' : '')}
    &:visited {
      color: ${({ outline }: StyleBlueButtonProps) => (outline ? CEB_COLOR('BLUE_RIBBON') : 'white')};
    }
    &[disabled] {
      cursor: default;
      ${({ outline }: StyleBlueButtonProps) =>
        outline
          ? `border: solid 2px ${CEB_COLOR('BLUEY_GRAY')}; color: ${CEB_COLOR(
              'BLUEY_GRAY',
            )};`
          : `background: ${CEB_COLOR('BLUEY_GRAY')}`}
    }
  `;
}

const button = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button {...props} />
);
const StyledBlueButton = styleBlueButton(button);
const StyledLink = styleBlueButton(Link);

interface BlueButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    StyleBlueButtonProps {
  funBlue?: boolean;
}

interface BlueButtonLinkProps extends LinkProps, StyleBlueButtonProps {
  disabled?: boolean;
}

export function BlueButtonLink({
  dark = false,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  outline = false,
  padding,
  ...props
}: BlueButtonLinkProps) {
  const component = React.useMemo(
    () => (disabled ? StyledBlueButton : StyledLink),
    [disabled],
  );
  return React.createElement(component, {
    dark,
    disabled,
    fontSize,
    fontWeight,
    fullWidth,
    outline,
    padding,
    ...props,
  });
}

export function BlueButton({
  dark = false,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  funBlue = false,
  outline = false,
  padding,
  ...props
}: BlueButtonProps) {
  return (
    <StyledBlueButton
      dark={dark}
      disabled={disabled}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fullWidth={fullWidth}
      outline={outline}
      padding={padding}
      bgColor={funBlue ? 'COLOR_NICE_BLUE' : 'COLOR_BLUE_RIBBON'}
      {...props}
    />
  );
}

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    StyleBlueButtonProps {
  className?: string;
}

export function Button({
  bgColor,
  className,
  dark,
  disabled = false,
  fontSize = '18px',
  fontWeight = 'bold',
  fullWidth = false,
  outline = false,
  padding,
  ...props
}: ButtonProps) {
  return (
    <StyledBlueButton
      dark={dark}
      bgColor={bgColor}
      disabled={disabled}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fullWidth={fullWidth}
      outline={outline}
      padding={padding}
      className={className}
      {...props}
    />
  );
}
