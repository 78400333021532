import React from 'react';
import { CEB_PORTAL_URL, RECAPTCHA_SITEKEY } from 'app-constants';

import { FormCols } from 'components/common/json_form';

import {
  StyledFormLabel,
  StyledValidatedInput,
  StyledValidatedSelect,
  StyledValidatedButton,
} from '../common/StyledForm';

import {
  ValidatedForm,
  ValidatedInput,
} from 'components/common/validated-form';

import Recaptcha from 'react-recaptcha';
import { SpinnerButtonMessage } from './SpinnerButtonMessage';
import PurchasePage from './PurchasePage';
import { useNavigate } from 'react-router';
import usKeyFilter from 'util/usKeyfilter';
import { useLocation } from 'react-router-dom';
import { useFuseBillPurchase } from './useFuseBillPurchase';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { LoadingEllipses } from 'components/common/LoadingEllipses';

const gutter = 25;

export function BillingAddress(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const onErrors = React.useCallback(
    props.onErrors ||
      (errors => {
        navigate('/', { state: { errors } });
      }),
  );

  const onSuccess = React.useCallback(
    props.onSuccess ||
      (() => {
        window.parent.postMessage({ cmd: 'subscribe' }, CEB_PORTAL_URL);
      }),
  );

  const [processing, onPurchase] = useFuseBillPurchase(onSuccess, onErrors);
  const [recaptcha, setRecaptcha] = React.useState('');

  const billingInfo = props.billingInfo || location.state;

  const onSubmit = React.useCallback(async address => {
    onPurchase({
      ...billingInfo,
      ...address,
    });
  });

  const verifyCallback = React.useCallback(response => {
    setRecaptcha(response);
  });

  const processingMessage =
    props.processingMessage || 'Processing your payment...';

  return (
    <PurchasePage title="Your Billing Information">
      <StyledBillingAddress>
        <p>To complete your order, please enter your billing address:</p>
        <ValidatedForm onSubmit={onSubmit}>
          <StyledFormLabel
            label="Address Line 1"
            hint="Street address, P.O box, company name"
          >
            <StyledValidatedInput
              type="text"
              name="address1"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.line1}
              required
            />
          </StyledFormLabel>

          <StyledFormLabel
            label="Address Line 2"
            hint="Apartment, suite, unit, building, floor, etc."
          >
            <StyledValidatedInput
              type="text"
              name="address2"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.line2}
            />
          </StyledFormLabel>

          <StyledFormLabel label="City">
            <StyledValidatedInput
              type="text"
              name="city"
              keyPressFilter={usKeyFilter}
              defaultValue={props.user?.addressInfo?.billingAddress?.city}
              required
            />
          </StyledFormLabel>

          <FormCols gutter={gutter} className="billing-state-zip">
            <StyledFormLabel label="State">
              <StyledValidatedSelect
                name="stateId"
                defaultValue={
                  props.user?.addressInfo?.billingAddress?.stateId || '18'
                }
                required
              >
                <option value="15">Alabama</option>
                <option value="14">Alaska</option>
                <option value="16">Arizona</option>
                <option value="17">Arkansas</option>
                <option value="18">California</option>
                <option value="19">Colorado</option>
                <option value="20">Connecticut</option>
                <option value="21">Delaware</option>
                <option value="22">District of Columbia</option>
                <option value="23">Florida</option>
                <option value="24">Georgia</option>
                <option value="25">Hawaii</option>
                <option value="26">Idaho</option>
                <option value="27">Illinois</option>
                <option value="28">Indiana</option>
                <option value="29">Iowa</option>
                <option value="30">Kansas</option>
                <option value="31">Kentucky</option>
                <option value="32">Louisiana</option>
                <option value="33">Maine</option>
                <option value="34">Maryland</option>
                <option value="35">Massachusetts</option>
                <option value="36">Michigan</option>
                <option value="37">Minnesota</option>
                <option value="38">Mississippi</option>
                <option value="39">Missouri</option>
                <option value="40">Montana</option>
                <option value="41">Nebraska</option>
                <option value="42">Nevada</option>
                <option value="43">New Hampshire</option>
                <option value="44">New Jersey</option>
                <option value="45">New Mexico</option>
                <option value="46">New York</option>
                <option value="47">North Carolina</option>
                <option value="48">North Dakota</option>
                <option value="49">Ohio</option>
                <option value="50">Oklahoma</option>
                <option value="51">Oregon</option>
                <option value="52">Pennsylvania</option>
                <option value="53">Puerto Rico</option>
                <option value="54">Rhode Island</option>
                <option value="55">South Carolina</option>
                <option value="56">South Dakota</option>
                <option value="57">Tennessee</option>
                <option value="58">Texas</option>
                <option value="59">Utah</option>
                <option value="60">Vermont</option>
                <option value="61">Virginia</option>
                <option value="62">Washington</option>
                <option value="63">West Virginia</option>
                <option value="64">Wisconsin</option>
                <option value="65">Wyoming</option>
              </StyledValidatedSelect>
            </StyledFormLabel>
            <StyledFormLabel label="Zip Code">
              <StyledValidatedInput
                label="Zip Code"
                type="text"
                name="zipCode"
                defaultValue={
                  props.user?.addressInfo?.billingAddress?.postalZip
                }
                validator={/^\d{5}$/}
                required
              />
            </StyledFormLabel>
          </FormCols>

          <input id="countryId" name="countryId" type="hidden" value="840" />
          <input
            id="makeDefault"
            name="makeDefault"
            type="hidden"
            value="true"
          />
          <input
            id="collectionAmount"
            name="collectionAmount"
            type="hidden"
            value={props.price}
          />
          <ValidatedInput
            name="recaptcha"
            type="hidden"
            value={recaptcha}
            required
          />

          <Recaptcha
            sitekey={RECAPTCHA_SITEKEY}
            render="explicit"
            verifyCallback={verifyCallback}
          />

          {!processing ? (
            <StyledValidatedButton className="billing-button" type="submit">
              {props.price ? (
                <>
                  <i className="fas fa-lock" />
                  Charge ${props.price} now
                </>
              ) : (
                'Update Payment Method'
              )}
            </StyledValidatedButton>
          ) : (
            <SpinnerButtonMessage message={`${processingMessage}`} />
          )}
        </ValidatedForm>
      </StyledBillingAddress>
    </PurchasePage>
  );
}
const StyledBillingAddress = styled.div`
  label div {
    margin-bottom: 27px;
  }

  .label-text {
    margin: 0 0 5px 0;
  }

  .hint-text {
    margin: 0 0 11px 0;
  }

  select {
    box-shadow: none;
  }

  form {
    max-width: 360px;
  }

  .billing-state-zip .form-col:last-child {
    max-width: 136px;
  }

  p {
    line-height: 1.8;
    margin-bottom: 25px;
  }

  .billing-button button {
    margin-top: 20px;
    min-width: 214px;
    width: auto !important;
    padding: 10px 20px;
    white-space: nowrap;
  }

  i {
    margin-right: 1em;
  }
`;
