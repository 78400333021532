import React from 'react';
import { ValidatedFormContext } from '.';

interface ValidatedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
}

export const ValidatedButton: React.FC<ValidatedButtonProps> = ({
  children,
  disabled,
  ...props
}) => {
  const { valid = false } = React.useContext(ValidatedFormContext) || {};

  return (
    <button disabled={!valid || disabled} {...props}>
      {children}
    </button>
  );
};
