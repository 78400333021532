import React from 'react';
import MaskedInput from 'react-maskedinput';
import { Validated, ValidatedProps } from './Validated';

export interface ValidatedInputProps extends Omit<ValidatedProps, 'element'> {}

export const ValidatedInput = React.forwardRef<
  HTMLInputElement | HTMLSelectElement,
  ValidatedInputProps
>(({ mask, placeholderChar, ...props }, ref) =>
  mask ? (
    <Validated
      element={MaskedInput}
      mask={mask}
      placeholderChar={placeholderChar}
      {...props}
    />
  ) : (
    <Validated element="input" ref={ref} {...props} />
  ),
);

ValidatedInput.displayName = 'ValidatedInput';
