import React from 'react';
import { postFusebill } from './postFusebill';

export function useFuseBillPurchase(onSuccess, onErrors) {
  const [processing, setProcessing] = React.useState(false);

  const onSubmit = React.useCallback(async billing => {
    setProcessing(true);

    const response = await postFusebill(billing);

    const json = await response.json();

    if (response.ok) {
      onSuccess(true);
    } else {
      onErrors(json && (json.Errors || [json.Message]));
      setProcessing(false);
    }
  });

  return [processing, onSubmit];
}
